import React, { useState } from 'react';
import Header from '../sharedComponents/Header';
import Footer from '../sharedComponents/footer';
import { Link, useNavigate } from 'react-router-dom';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';

export const cardsData = [
  {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/medhost.webp",
    bgColor: 'bg-blue-500',
    altText: 'medhost',
    page: 'medhost',
    description: `MEDHOST is a trusted healthcare information technology partner with a firm grasp on the industry's transformation. Currently, MEDHOST provides market-leading enterprise, departmental and healthcare engagement solutions to more than 1,000 healthcare facilities.`,
    title: 'Med Host',
    isMobile: false
  },
  {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/cloudshim-cloud-management-and-cost-optimization-tool.webp",
    bgColor: 'bg-orange-500',
    altText: 'cloudshim-cloud-management-and-cost-optimization-tool',
    page: 'cloudshim',
    description: 'Cloudshim is a platform that allows to easily user to visualize, track and map their AWS cloud resources. The platform is self-serve and has a rich set of features to ensure customers are running lean on the cloud.',
    title: 'Cloudshim',
    isMobile: false

  },
  {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/abb-digital-transformation-enhancing-efficiency-with-iot-integration.webp",
    bgColor: 'bg-red-500',
    altText: 'abb-digital-transformation-enhancing-efficiency-with-iot-integration',
    page: 'abb',
    description: `ABB is a global engineering company that provides solutions to make the future more sustainable and resource-efficient. ABB's electrification business enables the intelligent and efficient use of electricity from substation to socket. ABB's electrical safety solutions protect against faults such as residual currents, surges, earth fault currents, or arc faults for a variety of applications, including solar panels, heat pumps, and electric vehicles`,
    title: 'ABB',
    isMobile: false

  },
  {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/asset-panda-asset-tracking-and-management-platform.webp",
    bgColor: 'bg-blue-500',
    altText: 'asset-panda-asset-ttracking-and-management-platform',
    description: `Asset Panda's asset inventory management tool works on the devices you already have with you all the time – smartphones, tablets and computers – so there's no need to learn something entirely new. No matter where you are, you'll know when items are missing, depreciating or need to be replaced.`,
    title: 'Asset Panda',
    page: 'assetpanda',
    isMobile: true
  },

  {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/cryoport-temperature-controlled-logistics-and-supply-chain-solution.webp",
    bgColor: 'bg-blue-500',
    altText: 'cryoport-temperature-controlled-logistics-and-supply-chain-solution',
    page: 'cryoport',
    description: 'Cryoport Systems is a market leader in globally integrated temperature-controlled supply chain support for critical, irreplaceable products and materials in the life sciences industry. We are the proud single provider that supports all aspects of the life sciences supply chain.',
    title: 'Cryoport',
    isMobile: false

  },

  {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/cigna-healthcare-platform.webp",
    bgColor: 'bg-[#003FF5]',
    altText: 'cigna-healthcare-platform',
    title: 'Cigna',
    description: 'Cigna is a Fortune 15 global healthcare and insurance company founded in 1982, serving over 175 million customers worldwide. With a workforce of more than 70,000 employees, Cigna offers a diversified portfolio that includes health, life, accident insurance, and Medicare and Medicaid products.',
    page: 'cigna',
    isMobile: false
  },
  {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/cryoport-temperature-controlled-logistics-and-supply-chain-solution.webp",
    bgColor: 'bg-red-500',
    altText: 'cryoport-temperature-controlled-logistics-and-supply-chain-solution',
    title: 'E Learning App',
    description: 'Webkorps successfully developed a confidential e-learning application designed to provide a comprehensive and engaging learning experience for users.',
    page: 'elearningapp',
    isMobile: false
  }, {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/abp-news-mobile-application-for-prominent-media-organization.webp",
    bgColor: 'bg-[#A11F15]',
    altText: 'abp-news-mobile-application-for-prominent-media-organization',
    title: 'ABP News',
    description: 'ABP News is a prominent media organization in India, delivering timely news and updates across various platforms. ',
    page: 'abpnews',
    isMobile: false
  }, {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/verizon-rpa-powered-hr-management-automation-tool.webp",
    bgColor: 'bg-[#EC3D26]',
    altText: 'verizon-rpa-powered-hr-management-automation-tool',
    title: 'Verizon',
    description: 'Verizon has initiated a project to enhance its Human Resource Management Automation (HRMA) by integrating Robotic Process Automation (RPA) into its HR operations.',
    page: 'verizon',
    isMobile: false
  }, {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/inkind-customer-loyalty-and-rewards-management-software-for-restaurant.jpg",
    bgColor: 'bg-[#E0B95E]',
    altText: 'inkind-customer-loyalty-and-rewards-management-software-for-restaurant',
    title: 'inKind',
    description: 'inKind is an innovative platform designed to reward loyal customers while supporting local restaurants. Founded by Johann Moonesinghe, inKind allows diners to earn cash back and exclusive rewards every time they dine at participating restaurants.',
    page: 'inkind',
    isMobile: false
  }, {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/owens-corning-advanced-analytics-and-supply-chain-optimization-platforms.webp",
    bgColor: 'bg-[#C3307B]',
    altText: 'owens-corning-advanced-analytics-and-supply-chain-optimization-platforms',
    title: 'Owens Corning',
    description: 'Owens Corning is a leading American company specializing in the development and production of insulation, roofing, and fiberglass composites.',
    page: 'owenscorning',
    isMobile: false
  }, {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/cardinalhire-online-recruiting-software-and-hiring-platform.webp",
    bgColor: 'bg-blue-800',
    altText: 'cardinalhire-online-recruiting-software-and-hiring-platform',
    title: 'Cardinal Hire',
    description: 'CardinalHire is a dynamic platform designed to bridge the gap between top tech talent and leading tech companies.',
    page: 'cardinalhire',
    isMobile: false
  }, {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/scoutr-talent-management-system-with-advanced-matching-algorithms.webp",
    bgColor: 'bg-[#2E4488]',
    altText: 'scoutr-talent-management-system-with-advanced-matching-algorithms',
    title: 'Scoutr',
    description: 'Scoutr is an innovative platform designed to transform talent management by connecting organizations with both internal employees and external candidates.',
    page: 'scoutr',
    isMobile: false
  },
  {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/acima-lease-to-own-solutions-by-webkorps.jpg",
    bgColor: 'bg-[#020441]',
    altText: 'acima-lease-to-own-solutions-by-webkorps',
    title: 'ACIMA',
    description: 'Acima is a prominent lease-to-own service that allows customers to acquire items without the need for traditional credit or loans.',
    page: 'acima',
    isMobile: false
  }, {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/styla-streamlining-e-commerce-with-pwas-and-no-code-solutions.jpg",
    bgColor: 'bg-[#4A33CC]',
    altText: 'styla-streamlining-e-commerce-with-pwas-and-no-code-solutions',
    title: 'Styla',
    description: 'Styla is a cutting-edge storefront technology company that provides five integrated technologies designed for e-commerce platforms such as BigCommerce and Shopware.',
    page: 'styla',
    isMobile: false
  },
  {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/pebble-custom-mobile-app-for-pebble-smartwatches.webp",
    bgColor: 'bg-red-500',
    altText: 'pebble-custom-mobile-app-for-pebble-smartwatches',
    title: 'Pebble',
    description: 'Pebble is a renowned smartwatch brand known for its innovative features and user-friendly interface',
    page: 'pebble',
    isMobile: true
  },
  {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/sonic-healthcare-ai-driven-platform-for-diagnostic-servicesn.webp",
    bgColor: 'bg-blue-900',
    altText: 'sonic-healthcare-ai-driven-platform-for-diagnostic-servicesn',
    title: 'Sonic Healthcare',
    description: 'Sonic Healthcare is a prominent global healthcare company that specializes in providing high-quality diagnostic services.',
    page: 'sonichealthcare',
    isMobile: true
  },
  {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/paypal-payment-platform-for-merchants.webp",
    bgColor: 'bg-orange-500',
    altText: 'paypal-payment-platform-for-merchants',
    description: 'PayPal recognized the need to improve its existing features to meet the evolving demands of merchants and consumers. Users sought a more integrated and customizable checkout experience that would allow them to tailor payment forms according to their branding.',
    title: 'PayPal',
    page: 'paypal',
    isMobile: false
  }, {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/digikagaz-document-automation-for-legal-professionals.webp",
    bgColor: 'bg-blue-800',
    altText: 'digikagaz-document-automation-for-legal-professionals',
    title: 'Digikagaz',
    description: 'DigiKagaz was conceived with the vision of revolutionizing the way legal professionals and individuals handle document automation.',
    page: 'digikagaz',
    isMobile: false
  },
  {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/bhaibhandhu.webp",
    bgColor: 'bg-purple-500',
    altText: 'bhai-bandhu-mobile-and-web-app-b2b-marketplace',
    title: 'Bhai Bandhu',
    description: 'Bhai Bandhu is an innovative B2B marketplace specifically designed for Indian ethnic wear, providing a platform that connects wholesalers with retailers and agents.',
    page: 'bhaibandhu',
    isMobile: true
  }, {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/the-fragrances-shop-mobile-app-to-enhancing-customer-engagement-and-loyalty.webp",
    bgColor: 'bg-blue-900',
    altText: 'the-fragrances-shop-mobile-app-to-enhancing-customer-engagement-and-loyalty',
    title: 'The Fragrance Shop',
    description: `The Fragrance Shop (TFS) is the UK's largest independent retailer of fragrances and beauty products, boasting over 200 stores nationwide and a comprehensive online presence.`,
    page: 'thefragranceshop',
    isMobile: true
  },
  {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/my-kia-app-mobile-app-for-integrated-sales-and-service.webp",
    bgColor: 'bg-gray-500',
    altText: 'my-kia-app-mobile-app-for-integrated-sales-and-service',
    description: `Standalone mobile app for Kia owners, integrating sales, service management, and loyalty programs to streamline the ownership experience.`,
    title: 'My Kia APP',
    page: 'mykiaapp',
    isMobile: true
  },
  {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/puravankara.webp",
    bgColor: 'bg-blue-800',
    altText: 'puravankara-email-solution-for-enhanced-communication-and-engagement',
    title: 'Purvankara',
    description: `Puravankara Limited is one of India's most admired and trusted real estate players, headquartered in Bengaluru. `,
    page: 'purvankara',
    isMobile: false
  },
  {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/canopie-mobile-app-for-maternal-mental-health.webp",
    bgColor: 'bg-blue-100',
    altText: 'canopie-mobile-app-for-maternal-mental-health',
    description: 'Canopie is a pioneering mobile application designed to enhance maternal health by providing accessible, evidence-based preventative mental health care for new and expecting mothers.',
    title: 'Canopie',
    page: 'canopie',
    isMobile: false

  },
  {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/shree-laxmi-sheels-crm-system-with-customizable-dashboards.webp",
    bgColor: 'bg-b-800',
    altText: 'shree-laxmi-sheels-crm-system-with-customizable-dashboards',
    title: 'Shree Laxmi Steels',
    description: 'Shree Laxmi Steels is a prominent Indian steel manufacturing brand committed to delivering high-quality steel products.',
    page: 'shreelaxmisteels',
    isMobile: false
  },
  {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/property-finder-buyer-centric-real-estate-platform.webp",
    bgColor: 'bg-blue-800',
    altText: 'property-finder-buyer-centric-real-estate-platform',
    title: 'Property Finder',
    description: 'Property Finder is a specialized platform designed to connect buyers with the right properties, acting as a bridge between clients and the real estate market. By representing buyers in property transactions',
    page: 'propertyfinder',
    isMobile: false
  },
  {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/tonik.webp",
    bgColor: 'bg-blue-800',
    altText: 'tonik-bank-mobile-app-for-enhanced-ui/ux-and-scalability',
    title: 'Tonik',
    description: 'Tonik Bank is a pioneering digital-only bank in the Philippines, offering a range of banking products and services through its innovative mobile application',
    page: 'tonik',
    isMobile: true
  },
  {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/wazir-performance-security-and-feature-upgrades-for-a-leading-crypto-app.webp",
    bgColor: 'bg-white-800',
    altText: 'wazir-performance-security-and-feature-upgrades-for-a-leading-crypto-app',
    title: 'Wazir',
    description: 'Wazir is a leading cryptocurrency trading application that enables users to buy, sell, and trade various cryptocurrencies.',
    page: 'wazir',
    isMobile: true
  }, {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/ethmeme-simplifying-crypto-token-creation.webp",
    bgColor: 'bg-blue-800',
    altText: 'ethmeme-simplifying-crypto-token-creation',
    title: 'EtheMEME',
    description: 'Ethmeme.fun is an innovative platform designed to simplify the process of creating and trading cryptocurrency tokens.',
    page: 'ethememe',
    isMobile: false
  }, {
    imageSrc: "https://webkorps-app.s3.us-east-1.amazonaws.com/wendy-digital-ordering-solutions-for-food-industry.webp",
    bgColor: 'bg-blue-800',
    altText: 'wendy-digital-ordering-solutions-for-food-industry',
    title: `Wendy's`,
    description: `Wendy's, a leading fast-food chain, has recently introduced several innovative features aimed at enhancing customer experience and operational efficiency.`,
    page: 'wendy',
    isMobile: false
  },

];
const CaseStudy = () => {
  const [activeTab, setActiveTab] = useState("All");
  const navigate = useNavigate()

 
  const filteredData = cardsData.filter(card => {
    if (activeTab === 'All') return true;
    if (activeTab === 'Mobile') return card.isMobile === true;
    if (activeTab === 'Web') return card.isMobile === false;
  });
  return (
    <>
     <Helmet>
        <title>Our Work and Case Study | Webkorps</title>
        <meta
          name="description"
          content="Explore our latest work and case studies across various technologies and industries. See how Webkorps delivers innovative IT solutions and services that drive business growth and efficiency."
        />
        <link rel="canonical" href="https://www.webkorps.com/case-study" />
      </Helmet>
      <Header />
      <div className="bg-gray-100 w-full text-center md:text-left ">
        <div className="flex flex-col md:flex-row md:flex-row-reverse items-center justify-end md:justify-between w-full px-0 xl:pl-[160px] ipad-pro:pl-[100px] md:pr-0 relative">
        <img width="800" height="600"  sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy" src="https://webkorps-app.s3.us-east-1.amazonaws.com/our-work-and-case-study.webp" alt="our-work-and-case-study" className="xl:w-[829px] ipad-pro:w-[65%] md:w-[65%]  xl:h-[504px] m-0" />
          <div className="md:absolute   left-0 w-full p-4 md:w-[82%] lg:w-[85%] sm:w-full pl-[7%] md:pl-[5%] xl:pl-[160px] ipad-pro:pl-[100px]">
            <h2 className=" text-[33px] leading-[46px] md:text-[25px] md:leading-[38px] ipad-pro:text-[33px] ipad-pro:leading-[46px] xl:text-[33px] xl:leading-[46px] font-bold  mb-4">Our Work</h2>
            <p className="text-[23px] md:text-[18px] md:leading-[23px] ipad-pro:text-[23px] ipad-pro:leading-[33px] xl:text-[23px] xl:leading-[33px] leading-[32px] text-gray-600 w-full md:w-[40%] mb-0">
              Our collaboration with cutting-edge scaleups has been instrumental in
              propelling their offerings forward and tackling intricate challenges.
              We take immense pride in some of our standout projects from these partnerships.
            </p>
          </div>
        </div>
      </div>
      <div className="flex w-full px-[7%] md:px-[5%] xl:px-[160px] ipad-pro:px-[100px] justify-start mt-8   bg-white  overflow-hidden">
        <ul className="flex text-center items-center w-full sm:w-[100%] md:w-[100%] lg:w-[100%] md:space-x-3 font-semibold bg-gray-100 rounded-full p-1 justify-between px-4 md:px-6">
          <li
            className={`px-2 sm:px-6 md:px-8 py-2 sm:py-2 text-center md:py-2 rounded-full text-[13px] lg:text-[20px] sm:text-[13px] md:text-[20px] leading-[28px] cursor-pointer font-bold ${activeTab === 'All' ? 'bg-white text-black' : 'bg-gray-100 text-gray-800 hover:bg-white hover:text-black'
              }`}
            onClick={() => setActiveTab('All')}
          >
            All
          </li>
          <li
            className={`px-2 sm:px-6 md:px-8 md:py-2 py-2 rounded-full text-[13px] lg:text-[20px] sm:text-[13px] md:text-[20px] leading-[18px] cursor-pointer font-bold ${activeTab === 'Mobile' ? 'bg-white text-black' : 'bg-gray-100 text-gray-800 hover:bg-white hover:text-black'
              }`}
            onClick={() => setActiveTab('Mobile')}
          >
            Mobile Application
          </li>
          <li
            className={`px-2 sm:px-6 md:px-8 md:py-2 py-2  rounded-full text-[13px] lg:text-[20px] sm:text-[13px] md:text-[20px] leading-[18px] cursor-pointer font-bold ${activeTab === 'Web' ? 'bg-white text-black' : 'bg-gray-100 text-gray-800 hover:bg-white hover:text-black'
              }`}
            onClick={() => setActiveTab('Web')}
          >
            Web Application
          </li>
        </ul>
      </div>

      <div className="w-full pl-[7%] xl:px-[160px] ipad-pro:px-[100px] pr-[4%] xxl:pr-[10%] ipad-pro:pr-[3%] md:pr-[5%] md:px-[5%] mt-3  relative z-0 overflow-hidden ">
      <div className="grid grid-cols-1  md:grid-cols-2 xl:grid-cols-3 ipad-pro:grid-cols-2 gap-8 xl:gap-8 ipad-pro:gap-8 mt-6 mb-8 ipad-sum:justify-items-center">
          {filteredData.map((card, index) => (
            <div
              key={index}
              className="relative w-[352px] xl:w-[352px] ipad-pro:w-[352px] md:w-[330px]  h-[328px] rounded-lg shadow-2xl overflow-hidden group z-10 p-2"
              onClick={() => {
                navigate(`/case-study/${card.page}`);
              }}
            >
              <div className="absolute inset-0 bg-white clip-triangle"></div>
              <div className={`absolute inset-0 ${card.bgColor} clip-triangle-reverse`}></div>

              <div className="relative z-10 flex items-center justify-center h-full">
                <Link to={`/case-study/${card.page}`}>
                  <img width="800" height="600"  sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy" src={card.imageSrc} alt={card.altText} className="max-w-full" />
                </Link>

                <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col justify-center items-start p-7 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                  <div className="mb-1">
                    <FontAwesomeIcon icon={faGlobe} className="text-white text-xl" />
                  </div>
                  <h2 className="text-white text-xl font-bold mb-1">{card.title}</h2>
                  <p className="text-white text-[12px] font-bold">{card.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </>
  );
}

export default CaseStudy;
