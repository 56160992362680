import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../sharedComponents/Header';
import Footer from '../sharedComponents/footer';
import { Helmet } from 'react-helmet-async';

export const OfferCard = ({ title, details }) => {
  const [active, setActive] = useState(false); // State to manage active card

  return (
    <div
      className={`w-auto xl:w-[480px] ipad-pro:w-[400px] h-[160px] xl:h-[160px] ipad-pro:h-[100%]  ipad-pro:p-[5rem] bg-[#D0EAFF] rounded-lg shadow-md transition-all duration-300 ease-in-out items-center justify-center flex flex-col 
      ${active ? 'hover:h-[250px] xl:h-[250px] bg-blue-100 scale-105' : 'hover:h-[160px] hover:bg-blue-300'}`}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <h2
        className="text-[20px] lg:text-[33px] leading-[46.2px] font-bold text-center"
        dangerouslySetInnerHTML={{ __html: title }}
      ></h2>
      {
        active ? <div
          className={`mt-2 transition-opacity duration-300 text-center
          ${active ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
        >
          <p className="text-[15px] font-semibold">{details}</p>
        </div> : <div></div>
      }

    </div>
  );
};
function DigitalServices() {
  const location = useLocation();

  const offers = [
    { title: 'Application <br/>Modernization', details: 'We revamp your legacy applications with modern technologies, improving performance, scalability, and adaptability to future business needs.' },
    { title: 'MI/AI <br/>Production', details: 'We develop cutting-edge machine learning and artificial intelligence solutions, empowering your business with intelligent automation and data-driven decision-making.' },
    { title: 'Digital Transformation Consulting', details: 'Our experts guide your business through digital transformation, helping you leverage the latest technologies to enhance efficiency, innovation, and growth.' },
    { title: 'Big Data Infrastructure <br/> & Management', details: 'Our team designs and manages robust Big Data infrastructure, enabling efficient data storage, processing, and analysis for actionable business insights.' },
    { title: 'Turn-Key IoT <br/> Development', details: 'We deliver end-to-end IoT solutions, from concept to deployment, enabling your business to harness the power of connected devices and smart technology.' },
    { title: 'Reengineering & Process Consulting', details: 'We optimize and reengineer your business processes, ensuring streamlined operations, enhanced productivity, and alignment with your digital transformation goals.' },
  ];
  const reasons = [
    "Deep Industry Expertise: Webkorps brings years of experience in digital transformation across multiple industries. Our deep understanding of industry-specific challenges enables us to design tailored solutions that drive innovation and growth in your business.",
    "Focus on Security and Compliance: Security and regulatory compliance are at the core of our digital solutions. We implement advanced security measures to protect your data and adhere to relevant industry regulations, giving you peace of mind as you undergo transformation.",
    "Holistic Digital Strategy: We develop a comprehensive digital roadmap that addresses every aspect of your business, from automation and process optimization to digital marketing and customer engagement. This ensures a smooth and impactful transformation.",
    "Continuous Support and Scalability: Webkorps offers ongoing support to ensure your digital transformation continues to evolve. Our scalable solutions are designed to grow with your business, providing the flexibility to adapt to new opportunities and challenges in the future.",
    "Seamless Technology Integration: Our team excels in integrating cutting-edge technologies with your existing systems, minimizing downtime and ensuring a seamless transition. We work to optimize your operations without disrupting your day-to-day business."
  ];


  return (
    <div className=' overflow-hidden'>
      <Helmet>
        <title>Digital Transformation Services, Consulting and Solutions</title>
        <meta
          name="description"
          content="Drive growth with our digital transformation services. As a top digital transformation consulting company, we offer tailored solutions to streamline processes, boost efficiency, and keep you ahead in the digital era."
        />
        <meta property="og:url" content="https://www.webkorps.com/digital-transformation" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Digital Transformation Services, Consulting and Solutions" />
        <meta property="og:description" content="Drive growth with our digital transformation services. As a top digital transformation consulting company, we offer tailored solutions to streamline processes, boost efficiency, and keep you ahead in the digital era." />
        <meta property="og:image" content="https://webkorps-app.s3.us-east-1.amazonaws.com/Digital+Transformation+Services.webp" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="webkorps.com" />
        <meta property="twitter:url" content="https://www.webkorps.com/digital-transformation" />
        <meta name="twitter:title" content="Digital Transformation Services, Consulting and Solutions" />
        <meta name="twitter:description" content="Drive growth with our digital transformation services. As a top digital transformation consulting company, we offer tailored solutions to streamline processes, boost efficiency, and keep you ahead in the digital era." />
        <meta name="twitter:image" content="https://webkorps-app.s3.us-east-1.amazonaws.com/Digital+Transformation+Services.webp" />
        <link rel="canonical" href="https://www.webkorps.com/digital-transformation" />
      </Helmet>
      <Header />
      <div className="w-full text-center md:text-left overflow-hidden">
        <div className="flex flex-col md:flex-col ipad-pro:flex-row xl:flex-row items-center px-[5%] xl:px-[160px] ipad-pro:px-[100px] justify-center text-center lg:text-left pb-10 p-6 bg-[#FAFAFA]">
          <div className='content   w-full  flex flex-col '>
            <h1 className="text-[33px] leading-[38px] xl:leading-[46.2px] font-bold  w-[100%]">Digital Transformation</h1>
            <h2 className="font-quicksand text-[28px] leading-[39.2px] font-bold text-center xl:text-left  ipad-pro:text-left text-[#072125] mt-3 md:mt-4 w-full">
            Transform Your Business for the Digital Future
            </h2>
            <p className="text-[#072125] mt-[20px] text-[18px] leading-[27.3px]  xl:w-[670px] ipad-pro:w-[350px]">
            In today’s rapidly evolving landscape, digital transformation is essential for staying competitive. At Webkorps, we recognize the importance of leveraging cutting-edge technologies and innovative strategies to redefine your business processes. Our comprehensive digital transformation services are designed to optimize efficiency, enhance customer engagement, and drive sustainable growth, ensuring your organization thrives in the digital age.          </p>
            </div>
          <div className='content  mt-6 xl:mt-0 ipad-pro:mt-6 md:mt-6  w-full   items-center  flex flex-col xl:items-end ipad-pro:items-end md:items-center '>
            <img width="800" height="600"  sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy" src="https://webkorps-app.s3.us-east-1.amazonaws.com/digital-transformation-services.webp" alt="digital-transformation-services" className="rounded-lg shadow-md w-full lg:w-[385px] lg:h-[415px] max-w-md" />
          </div>
        </div>

        {/* Offers Section */}
        <div className='flex lg:flex-row items-center  justify-center md:justify-start text-center  md:mx-[50px] xl:ml-[203px] xl:mr-[213px] ipad-pro:mx-[100px] mt-[52px]   w-full mb-[28px]'>
          <h1 className="text-[40px] leading-[56px] font-bold text-center">What We Offer?</h1>
        </div>
        <div className="relative flex flex-col md:flex-row lg:flex-row flex-wrap justify-between w-full md:px-[30px] xl:pl-[203px] xl:pr-[213px] ipad-pro:px-[85px] px-[4%] mb-[89px]">
          {offers.map((offer, index) => (
            <div
              key={offer.id}
              className="relative z-10 w-full sm:w-full md:w-1/2 xl:w-[45%] ipad-pro:w-[50%] p-5"
            >
              <OfferCard key={index} title={offer.title} details={offer.details} />
            </div>
          ))}
          <img width="800" height="600"  sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"
            src="https://webkorps-app.s3.us-east-1.amazonaws.com/dot-elements.svg"
            alt="dot-elements"
            className="absolute bottom-[-20px] left-[86px] z-0 w-[27%]"
          />
        </div>




        {/* Why Choose Section */}
        <div className="px-[7%] xl:px-[304px]  ipad-pro:px-[100px] rounded-[10px] shadow-md relative">
          <div className="relative">
            <h3 className="text-2xl font-bold text-center mb-4 md:mb-[32px]">
            Why Choose Webkorps for Your Digital Transformation? 
            </h3>

            <div className="relative w-full">
              <img width="800" height="600"  sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"
                src={'https://webkorps-app.s3.us-east-1.amazonaws.com/circleleft.svg'}
                alt="circleleft"
                className="absolute top-0 left-[40%]transform -translate-x-[65%] translate-y-[70%] w-[90%] xl:translate-y-[70%] xl:-translate-x-[115%] max-w-[800px] h-[70%] xl:h-[60%] xl:w-[50%]  pointer-events-none z-0"
              />
              <ul className="relative grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-8 text-gray-700 z-10 pb-[85px]">
                {reasons.map((reason, index) => {
                  const [title, description] = reason.split(': ');

                  return (
                    <li key={index} className="flex mb-[30px]">
                      <b className="text-[33px] leading-[46px] font-bold font-quicksand text-[#20484F]">
                        {(index + 1).toString().padStart(2, '0')}
                      </b>
                      <p className="ml-[10px] mt-1 text-[13px] leading-[18px] font-normal font-quicksand text-[#072125]">
                        <strong className="mb-[10px] block text-[19px] leading-[26px] font-bold font-quicksand text-[#072125]">
                          {title}
                        </strong>
                        {description}
                      </p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DigitalServices;
