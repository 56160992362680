import React from 'react';
import Header from '../sharedComponents/Header';
import Footer from '../sharedComponents/footer';
import { OfferCard } from './DigitalServices';
import CircleLeft from './../../assets/images/CircleLeft.svg'


function ITServices() {

  const offers = [
    { title: '24/7 <br/>Support', details: 'We provide 24/7 IT support to ensure your systems run smoothly, allowing you to focus on your core business while we handle technical issues.' },
    { title: 'Cloud Services<br/> Management', details: 'We manage your cloud services, optimizing performance and cost while ensuring security and compliance with industry standards.' },
    { title: 'Network<br/> Management', details: 'Our team monitors and manages your network infrastructure, ensuring optimal performance, security, and reliability for seamless business operations.' },
    { title: 'Data Backup<br/> and Recovery', details: 'Our managed backup and recovery solutions protect your critical data, ensuring quick restoration and business continuity in the event of a disaster.' },
    { title: 'Cybersecurity <br/> Solutions', details: 'We implement robust cybersecurity measures to protect your organization from threats, ensuring data integrity, compliance, and peace of mind.' },
    { title: 'IT Consulting <br/> Services', details: 'We offer strategic IT consulting to align your technology with business goals, driving innovation and improving efficiency across your organization.' },
  ];
  const reasons = [
    "Expertise Across IT Domains: Webkorps offers a team of skilled IT professionals with expertise in network management, cloud services, cybersecurity, and more. We provide comprehensive IT solutions tailored to meet the specific needs of your organization.",
    "Robust Security and Compliance: Security is a top priority at Webkorps. We implement industry-leading security practices to safeguard your systems and data. Our solutions also ensure compliance with regulatory standards, reducing risk and ensuring business continuity.",
    "Proactive Monitoring and Maintenance: Our managed IT services ensure your systems are continuously monitored and maintained, preventing issues before they arise. This proactive approach minimizes downtime and maximizes the performance of your IT infrastructure.",
    "Customized IT Solutions: We understand that every business is unique, which is why we tailor our IT solutions to your specific requirements. From cloud integration to data management, our solutions are designed to align with your business goals.",
    "Scalable and Cost-Efficient: Our managed IT solutions are designed to scale with your business. Whether you’re a growing startup or an established enterprise, we optimize IT resources to ensure cost efficiency while supporting your expansion and future needs."
  ];

 
  return (
    <div className=' overflow-hidden'>
      <Header />
      <div className="w-full text-center md:text-left overflow-hidden">
        <div className="flex flex-col md:flex-col ipad-pro:flex-row xl:flex-row items-center px-[5%] xl:px-[160px] ipad-pro:px-[100px] justify-center text-center lg:text-left pb-10 p-6 bg-[#FAFAFA]">
          <div className='content   w-full  flex flex-col '>
            <h1 className="text-[33px] leading-[38px] xl:leading-[46.2px] font-bold  w-[100%]">Manage IT Solution</h1>
            <h2 className="font-quicksand text-[28px] leading-[39.2px] font-bold text-center xl:text-left  ipad-pro:text-left text-[#072125] mt-3 md:mt-4 w-full">
            Streamline your IT operations with our comprehensive solutions.
            </h2>
            <p className="text-[#072125] mt-[20px] text-[18px] leading-[27.3px]  xl:w-[670px] ipad-pro:w-[350px]">
            Webkorps offers a wide range of IT management tools and services designed to simplify your IT infrastructure, improve efficiency, and enhance security. From cloud-based solutions to on-premises deployments, we have the expertise to help you optimize your IT environment and achieve your business goals. Our solutions are tailored to meet the unique needs of your organization, providing you with the flexibility and scalability you require.         </p>
          </div>
          <div className='content  mt-6 xl:mt-0 ipad-pro:mt-6 md:mt-6  w-full  items-center  flex flex-col xl:items-end ipad-pro:items-end md:items-center '>
            <img width="800" height="600"  sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy" src="https://webkorps-app.s3.us-east-1.amazonaws.com/manage-it-solution.webp" alt="manage-it-solution" className="rounded-lg shadow-md w-full lg:w-[385px] lg:h-[415px] max-w-md" />
          </div>
        </div>

        {/* Offers Section */}
        <div className='flex lg:flex-row items-center  justify-center md:justify-start text-center  md:mx-[50px] xl:ml-[203px] xl:mr-[213px] ipad-pro:mx-[100px] mt-[52px]   w-full mb-[28px]'>
          <h1 className="text-[40px] leading-[56px] font-bold text-center">What We Offer?</h1>
        </div>
        <div className="relative flex flex-col md:flex-row lg:flex-row flex-wrap justify-between w-full md:px-[30px] xl:pl-[203px] xl:pr-[213px] ipad-pro:px-[85px] px-[4%] mb-[89px]">
          {offers.map((offer, index) => (
            <div
              key={offer.id}
              className="relative z-10 w-full sm:w-full md:w-1/2 xl:w-[45%] ipad-pro:w-[50%] p-5"
            >
              <OfferCard key={index} title={offer.title} details={offer.details} />
            </div>
          ))}
          <img width="800" height="600"  sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"
            src="https://webkorps-app.s3.us-east-1.amazonaws.com/dot-elements.svg"
            alt="dot-elements"
            className="absolute bottom-[-20px] left-[86px] z-0 w-[27%]"
          />
        </div>




        {/* Why Choose Section */}
        <div className="px-[7%] xl:px-[304px]  ipad-pro:px-[100px] rounded-[10px] shadow-md relative">
          <div className="relative">
            <h3 className="text-2xl font-bold text-center mb-4 md:mb-[32px]">
            Why Choose Webkorps to Manage your IT Solution?
            </h3>

            <div className="relative w-full">
              <img width="800" height="600"  sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"
                src={'https://webkorps-app.s3.us-east-1.amazonaws.com/circleleft.svg'}
                alt="circleleft"
                className="absolute top-0 left-[40%] transform -translate-x-[65%] translate-y-[70%] w-[90%] xl:translate-y-[70%] xl:-translate-x-[115%] max-w-[800px] h-[70%] xl:h-[60%] xl:w-[50%]  pointer-events-none z-0"
              />
              <ul className="relative grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-8 text-gray-700 z-10 pb-[85px]">
                {reasons.map((reason, index) => {
                  const [title, description] = reason.split(': ');

                  return (
                    <li key={index} className="flex mb-[30px]">
                      <b className="text-[33px] leading-[46px] font-bold font-quicksand text-[#20484F]">
                        {(index + 1).toString().padStart(2, '0')}
                      </b>
                      <p className="ml-[10px] mt-1 text-[13px] leading-[18px] font-normal font-quicksand text-[#072125]">
                        <strong className="mb-[10px] block text-[19px] leading-[26px] font-bold font-quicksand text-[#072125]">
                          {title}
                        </strong>
                        {description}
                      </p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ITServices;
