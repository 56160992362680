import React from "react";
import Header from "./Header";
import CardContainer from "./Card";
import Main from "./main";
import ServicesListContainer from "./serviceLists";
import TopIntregation from "./topIntregation";
import { Helmet } from 'react-helmet-async';
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  return (
    <div className="overflow-hidden " >
      <Helmet>
        <title>Leading IT Solutions and IT Services Provider | Webkorps</title>
        <meta
          name="description"
          content="Discover innovative IT solutions with Webkorps, a trusted IT service provider offering scalable and secure IT services to drive your digital transformation and business growth."
        />
        <meta property="og:url" content="https://www.webkorps.com" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Leading IT Solutions and IT Services Provider | Webkorps" />
        <meta property="og:description" content="Discover innovative IT solutions with Webkorps, a trusted IT service provider offering scalable and secure IT services to drive your digital transformation and business growth." />
        <meta property="og:image" content="https://webkorps-app.s3.us-east-1.amazonaws.com/IT+Solutions+and+IT+Services+Provider.webp" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="webkorps.com" />
        <meta property="twitter:url" content="https://www.webkorps.com" />
        <meta name="twitter:title" content="Leading IT Solutions and IT Services Provider | Webkorps" />
        <meta name="twitter:description" content="Discover innovative IT solutions with Webkorps, a trusted IT service provider offering scalable and secure IT services to drive your digital transformation and business growth." />
        <meta name="twitter:image" content="https://webkorps-app.s3.us-east-1.amazonaws.com/IT+Solutions+and+IT+Services+Provider.webp" />
        <link rel="canonical" href="https://www.webkorps.com/" />
        <link rel="preload" href="https://webkorps-app.s3.us-east-1.amazonaws.com/leading-it-solutions-and-it-services-provider.webp" as="image" />
      </Helmet>
      <Header />

      <div className="w-full h-auto md:block">
        <div className="w-full  content">
          <div className="flex overflow-hidden flex-col ipad-pro:flex-col  xl:flex-row-reverse items-start md:items-start md:space-x-8">
            {/* Right Section (Image) */}
            <div className="w-[100%] xl:w-[80%] ipad-pro:w-full xl:pl-0 ipad-pro:pl-[100px] md:pl-[25px] ipad-sum:pl-[45px]">
              <img width="800" height="600"
                src="https://webkorps-app.s3.us-east-1.amazonaws.com/leading-it-solutions-and-it-services-provider.webp"
                alt="leading-it-solutions-and-it-services-provider"
                class="w-full h-[435px] ipad-sum:h-[590px] xl:h-[644px] ipad-pro:h-[800px]"
                loading="lazy"
                sizes="(max-width: 640px) 100vw, 
                (max-width: 1280px) 50vw, 
                33vw"
                srcSet="
                https://webkorps-app.s3.us-east-1.amazonaws.com/leading-it-solutions-and-it-services-provider.webp 800w,
                https://webkorps-app.s3.us-east-1.amazonaws.com/leading-it-solutions-and-it-services-provider.webp 1200w,
                https://webkorps-app.s3.us-east-1.amazonaws.com/leading-it-solutions-and-it-services-provider.webp 1600w
              "
              />
            </div>
            <div className="w-[100%] md:w-[100%] overflow-hidden pl-[7%] xl:pl-[130px] xl:pr-0 md:px-[20px] ipad-pro:px-[77px] max-w-[95%]">
              <div className="mt-6 text-center  md:text-left md:mt-25 lg:mt-20 ">
                <p
                  className="font-quicksand lg:tracking-wider font-bold text-[20px] md:text-[33px] xl:text-[33px]  ipad-pro:text-[37px] md:text-[36px] leading-7 md:leading-[46.2px] lg:leading-[46.2px] text-[#1887C9]"
                >
                  We Code Solutions, Not Just Software
                </p>
                <p
                  className="font-quicksand lg:tracking-wider text-[#0A3954] text-[26px] md:text-[45px] xl:text-[48px] ipad-pro:text-[59px] leading-[30px] md:leading-[60.2px] xl:leading-[67.2px] ipad-pro:leading-[70.7px] mt-4 text-[#0A3954] font-light"
                >

                  Step Into The Digital Age <br />
                  With Our Cutting-Edge <br />
                  IT Solutions!
                </p>
              </div>

              <div className="flex flex-col md:flex-row ipad-pro:flex-row xl:flex-row items-center justify-center md:justify-start ipad-pro:items-start  ipad-pro:justify-start gap-6  md:gap-9 xl:gap-9 ipad-pro:gap-6 mt-6 sm:flex-col sm:mt-8 md:mt-12 xl:mt-16 ipad-pro:mt-10">
                <button
                  type="button"
                  className="focus:outline-none focus:ring-4 focus:ring-blue-300 font-bold text-base md:text-lg w-[70%] sm:px-3 lg:px-3 md:py-3 md:w-[45%] xl:w-[40%] ipad-pro:w-[55%] px-3 py-3 md:px-3 text-center border-2 border-[#1887C9] text-[#1887C9] rounded-full bg-transparent"
                >

                  Calculate Project Online
                </button>

                <span className="absolute hidden group-hover:inline-block bg-black text-white text-sm px-2 py-1 rounded-md bottom-[110%] left-1/2 transform -translate-x-1/2">
                  Coming Soon
                </span>

                <button
                  type="button"
                  className="flex items-center align-center justify-center text-white focus:outline-none focus:ring-4 focus:ring-blue-300 font-bold rounded-full text-base text-center md:text-lg  w-[70%] md:w-[45%] xl:w-[40%] ipad-pro:w-[55%]  px-3 md:px-3 py-3 sm:py-5 md:py-2 bg-[#1887C9]"
                  onClick={() => { navigate('/contact') }}
                >
                  <img width="800" height="600" sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"

                    src="https://webkorps-app.s3.us-east-1.amazonaws.com/schedule-a-call.webp"

                    alt="schedule-a-call"
                    className="w-5 h-5 mr-2 md:w-10 md:h-10"
                  />
                  Schedule a Call
                </button>
              </div>
              <div className=" w-full  md:mt-10 mt-14  flex flex-col items-center sm:items-center md:items-start lg:items-start gap-6  ">
                <p
                  className="font-quicksand font-bold text-[28px] md:text-[28px] lg:text-[28px] leading-6 md:leading-7 lg:leading-[40px] text-[#000000]"
                >
                  Our Official Partners:
                </p>

                <div className="flex flex-wrap justify-center gap-6 xl:gap-6 md:gap-4 ipad-pro:gap-4 ">
                  <div className="w-[110px] sm:w-[110px] md:w-[110px] h-[62px] p-2 border-[0.63px] border-black rounded-[15px]  flex justify-center items-center shadow-lg">
                    <img width="800" height="600" sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"

                      src="https://webkorps-app.s3.us-east-1.amazonaws.com/our-official-partners-adobe.webp"
                      alt="our-official-partners-adobe"
                      className="max-w-full max-h-full w-[84px] h-[22px]"
                    />
                  </div>
                  <div className="w-[110px] sm:w-[110px] md:w-[110px] h-[62px] border-[0.63px] border-black rounded-[15px] p-2 flex justify-center items-center shadow-lg">
                    <img width="800" height="600" sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"

                      src="https://webkorps-app.s3.us-east-1.amazonaws.com/our-official-partners-manageengine.webp"
                      alt="our-official-partners-manageengine"
                      className="max-w-full max-h-full w-[83px] h-[46px]"
                    />
                  </div>
                  <div className="w-[110px] sm:w-[110px] md:w-[110px] h-[62px] border-[0.63px] border-black rounded-[15px]  p-2 flex justify-center items-center shadow-lg">
                    <img width="800" height="600" sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"

                      src="https://webkorps-app.s3.us-east-1.amazonaws.com/our-official-partners-microsoft.webp"
                      alt="our-official-partners-microsoft"
                      className="h-5 max-w-full w-[28px] h-[28px]"
                    />
                    <p className="sm:ml-1 md:ml-1 lg:ml-1 font-medium text-[10px] leading-[11px] sm:text-[10px] xl:text-[10px] xl:leading-[11px] ">Microsoft</p>
                  </div>

                  <div className="w-[110px] sm:w-[110px] md:w-[110px] h-[62px] border-[0.63px] border-black rounded-[15px]  p-2 flex justify-center items-center shadow-lg">
                    <img width="800" height="600" sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"

                      src="https://webkorps-app.s3.us-east-1.amazonaws.com/our-official-partners-hubspot.webp"
                      alt="our-official-partners-hubspot"
                      className="max-w-full  w-[74px] h-[21px]"
                    />
                  </div>
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>



      <ServicesListContainer />

      <CardContainer />
      <TopIntregation />

      <Main />
    </div>
  );
};
export default Home;
