const s3BaseUrl = 'https://webkorps-app.s3.us-east-1.amazonaws.com/';

const ClientsImg = [
  {
    id: 1,
    src: `${s3BaseUrl}abp-logo.webp`,
    alt: "abp-logo",
  },
  {
    id: 2,
    src: `${s3BaseUrl}acima-logo.webp`,
    alt: "acima-logo",
  },
  {
    id: 3,
    src: `${s3BaseUrl}cigna-logo.webp`,
    alt: "cigna-logo",
  },
  {
    id: 4,
    src: `${s3BaseUrl}cryoport-logo.webp`,
    alt: "cryoport-logo",
  },
  {
    id: 5,
    src: `${s3BaseUrl}digikagaz-logo.webp`,
    alt: "digikagaz-logo",
  },
  {
    id: 6,
    src: `${s3BaseUrl}ethmeme-logo.svg`,
    alt: "ethmeme-logo",
  },
  {
    id: 7,
    src: `${s3BaseUrl}cardinalhire.webp`,
    alt: "cardinalhire",
  },
  {
    id: 8,
    src: `${s3BaseUrl}property-finder-logo.webp`,
    alt: "property-finder-logo",
  },
  {
    id: 9,
    src: `${s3BaseUrl}puravankara-logo.webp`,
    alt: "puravankara-logo",
  },
  {
    id: 10,
    src: `${s3BaseUrl}asset-panda-logo.webp`,
    alt: "asset-panda-logo",
  },
  {
    id: 11,
    src: `${s3BaseUrl}sonic-healthcare-logo.webp`,
    alt: "sonic-healthcare-logo",
  },
  {
    id: 12,
    src: `${s3BaseUrl}cloudshim-logo.svg`,
    alt: "cloudshim-logo",
  },
  {
    id: 13,
    src: `${s3BaseUrl}wendy-logo.jpg`,
    alt: "wendy-logo",
  },
];

const ClientsImg2 = [
  {
    id: 1,
    src: `${s3BaseUrl}bhai-bandhu-logo.webp`,
    alt: "bhai-bandhu-logo",
  },
  {
    id: 2,
    src: `${s3BaseUrl}paypal-logo.webp`,
    alt: "paypal-logo",
  },
  {
    id: 3,
    src: `${s3BaseUrl}tonik-bank-logo.webp`,
    alt: "tonik-bank-logo",
  },
  {
    id: 4,
    src: `${s3BaseUrl}verizon-logo.webp`,
    alt: "verizon-logo",
  },
  {
    id: 5,
    src: `${s3BaseUrl}medhost-logo.webp`,
    alt: "medhost-logo",
  },
  {
    id: 6,
    src: `${s3BaseUrl}owenscorning-logo.webp`,
    alt: "owenscorning-logo",
  },
  {
    id: 7,
    src: `${s3BaseUrl}scoutr-logo.webp`,
    alt: "scoutr-logo",
  },
  {
    id: 8,
    src: `${s3BaseUrl}shree-laxmi-steels-logo.webp`,
    alt: "shree-laxmi-steels-logo",
    fill: true,
  },
  {
    id: 9,
    src: `${s3BaseUrl}styla-logo.webp`,
    alt: "styla-logo",
  },
  {
    id: 10,
    src: `${s3BaseUrl}pebble-logo.webp`,
    alt: "pebble-logo",
  },
  {
    id: 11,
    src: `${s3BaseUrl}the-fragrances-shop-logo.webp`,
    alt: "the-fragrances-shop-logo",
  },
  {
    id: 12,
    src: `${s3BaseUrl}inkind-logo.webp`,
    alt: "inkind-logo",
  },
];

export { ClientsImg, ClientsImg2 };
