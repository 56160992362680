import React from "react";
import Header from "../sharedComponents/Header";
import CardContainer from "./Card";
import AchievementCardContainer from "./AchievementCard";
import Footer from "../sharedComponents/footer";
import LeadersCardContainer from "./LeadersCard";
import { Helmet } from 'react-helmet-async';

const AboutContainer = () => {
  return (
    <div className="overflow-hidden ">
      <Helmet>
        <title>About Webkorps | Best IT Solutions and IT Services Provider</title>
        <meta
          name="description"
          content="Leading IT solutions provider with 12+ years of experience, offering custom software, web and mobile app development, IT consulting, and digital transformation services to drive business growth and efficiency"
        />
        <meta property="og:url" content="https://www.webkorps.com/about-us" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="About Webkorps | Best IT Solutions and IT Services Provider" />
        <meta property="og:description" content="Leading IT solutions provider with 12+ years of experience, offering custom software, web and mobile app development, IT consulting, and digital transformation services to drive business growth and efficiency." />
        <meta property="og:image" content="https://webkorps-app.s3.us-east-1.amazonaws.com/About+Webkorps.webp" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="webkorps.com" />
        <meta property="twitter:url" content="https://www.webkorps.com/about-us" />
        <meta name="twitter:title" content="About Webkorps | Best IT Solutions and IT Services Provider" />
        <meta name="twitter:description" content="Leading IT solutions provider with 12+ years of experience, offering custom software, web and mobile app development, IT consulting, and digital transformation services to drive business growth and efficiency." />
        <meta name="twitter:image" content="https://webkorps-app.s3.us-east-1.amazonaws.com/About+Webkorps.webp" />
        <link rel="canonical" href="https://www.webkorps.com/about-us" />
      </Helmet>
      <Header />
      <div
        className="content  w-full px-[7%] xl:px-[160px] ipad-pro:px-[100px] overflow-hidden"
      >

        <div className="flex justify-center mt-0 lg:mt-[42px] sm:mt-0 md:mt-[42px]">
          <div>
            <img width="800" height="600"  sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy" src="https://webkorps-app.s3.us-east-1.amazonaws.com/about.webp" alt="about " />          </div>
        </div>
        <div className="mt-[54px] text-center md:text-left lg:text-left sm:text-center bg-cover bg-right bg-no-repeat p-2"
        >
          <p className="font-quicksand font-medium text-[20px] leading-[22.4px]">
            our story
          </p>
          <p className="font-quicksand font-bold text-[23px] leading-[32.2px] mt-4">
            Empowering Businesses with Intelligent IT Solutions <br />Your trusted partner for innovative, data-driven success.
          </p>
          <div className="flex flex-col md:flex-row lg:flex-row sm:flex-col mt-4">
            <div className="w-full  lg:w-1/2  md:w-1/2">
              <p>
                Webkorps Services India Pvt Ltd. was founded with a vision to empower businesses through innovative and reliable IT solutions. From the very beginning, our mission has been to support clients in navigating the complexities of the digital world. As a services-based IT company, we specialize in providing customized solutions that cater to the unique needs of each client, ensuring they can thrive in today’s fast-paced business environment.
                <br />
                <br /> Our journey has been one of growth and transformation. Over the years, we have evolved into a team of highly skilled professionals who are not only passionate about technology but also committed to delivering the highest standards of service. At Webkorps, we pride ourselves on our ability to adapt and expand our expertise, always staying ahead of industry trends to bring the best solutions to our clients.
                <br />
              </p>
            </div>
            <div className="relative w-full lg:w-1/2 md:w-1/2 md:ml-16 lg:ml-16">
  <img width="800" height="600"  sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"
    src="https://webkorps-app.s3.us-east-1.amazonaws.com/circle.svg"
    alt="circle"
    className="absolute right-[-50px]  z-0 h-[385px]"
  />
  
  {/* Text Content */}
  <div className="relative z-10  p-4">
    <p>
      Collaboration and transparency are at the core of our values. We believe that by working closely with our clients, we can build strong partnerships based on trust, understanding, and shared success. Our approach combines cutting-edge technology with a personalized touch, allowing us to develop intelligent, data-driven solutions that offer a clear, unified view of our clients’ businesses. This empowers them to make informed, strategic decisions with confidence, and achieve long-term success.
      <br />
      <br />
      At Webkorps Services India Pvt Ltd., we are where technology meets reliability. Our clients’ success is not just our goal; it is our commitment. We look forward to continuing this journey with you, bringing you intelligent solutions that drive innovation and growth.
    </p>
  </div>
</div>

          </div>
        </div>
        {/* our vision container */}
        <div className="flex flex-col text-center md:text-left xl:text-left ipad-pro:text-left sm:text-center md:flex-col  ipad-pro:flex-col xl:flex-row sm:flex-col md:mt-[56px]">
          <div className="w-full  xl:w-1/2 mt-4 ipad-pro:w-full md:w-full md:justify-items-center  ipad-pro:justify-items-center">
            <img width="800" height="600"  sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy" src="https://webkorps-app.s3.us-east-1.amazonaws.com/vision.webp" alt="vision " />
          </div>
          <div className=" flex flex-col w-full sm:w-full xl:w-1/2 ipad-pro:w-full   md:w-full  xl:ml-16 ipad-pro:ml-0  md:ml-0">
            <p className="font-quicksand font-bold text-[33px] leading-[46.2px]">
              Our Vision
            </p>
            <div className="flex flex-col ipad-pro:flex-row xl:flex-col md:flex-row space-x-0 ipad-pro:space-x-10 xl:space-x-0  md:space-x-10 mt-4">              <p className="md:w-1/2 ipad-pro:w-1/2  xl:w-full w-full ">
              Our vision is to build a company where happy clients and happy employees drive our success. We believe that a positive and fulfilling work environment inspires our team to deliver exceptional results. By fostering a culture of collaboration, growth, and mutual respect, we create a place where our employees can thrive and, in turn, create meaningful, impactful solutions for our clients.
            </p>
              <p className="md:w-1/2 ipad-pro:w-1/2  xl:w-full w-full mt-5 md:mt-0 xl:mt-5 ipad-pro:mt-0 ">
                We are committed to building lasting relationships with our clients, understanding their needs, and exceeding their expectations. With each project, we strive to bring joy and satisfaction to both our clients and our team, as we believe that success is measured not just by outcomes but by the happiness and fulfillment we bring to all those we work with. Together, we aim to shape a future where innovation, trust, and happiness are at the heart of everything we do.
              </p>
            </div>

          </div>
        </div>
        {/* our core value */}
        <div className="mt-6 md:mt-14 flex flex-col justify-center  items-center md:items-start ipad-pro:items-start xl:items-center  sm:items-center ">
          <p className="font-quicksand font-bold text-[33px] leading-[46.2px] mb-6">
            Our Core Values
          </p>
          <CardContainer />
        </div>
      </div>
      <div className="mt-14 mb-7 flex flex-col justify-center items-center md:items-start h-[576px] bg-[#B7DAEE]" >
        <div className="content px-[6%] xl:px-[160px]  ipad-pro:px-[100px]  xxl:pr-[50px] xxl:pl-[160px] text-center md:text-left w-full pt-10">
          <p className="font-quicksand font-bold text-[33px] leading-[46.2px] mb-6">
            Our Achievements
          </p>
          <AchievementCardContainer />
          
        </div>
      </div>
      <div className="mb-[57px]">
        <LeadersCardContainer />
      </div>
      <Footer />
    </div>
  );
};
export default AboutContainer;