import React from 'react';
import Header from '../sharedComponents/Header';
import Footer from '../sharedComponents/footer';
import { OfferCard } from '../Services/DigitalServices';


function Services() {

  const offers = [
    { title: 'MVP <br/>Development', details: 'We help you quickly develop a Minimum Viable Product to validate your idea in the market, focusing on core features that address customer needs.' },
    { title: 'Agile Product<br/> Development', details: 'We adopt agile methodologies to ensure flexible and adaptive product development, enabling rapid iterations based on user feedback and market changes.' },
    { title: 'User Research <br/>and Validation', details: 'Our team conducts in-depth user research to validate your product concept, ensuring it aligns with market demand and user expectations.' },
    { title: 'Launch Strategy <br/>and Execution', details: 'Our experts devise comprehensive launch strategies to maximize market impact, from marketing tactics to user onboarding and feedback collection.' },
    { title: 'Product Design <br/>and Prototyping', details: 'We create intuitive designs and interactive prototypes that effectively communicate your vision, allowing for user feedback and iterative improvements.' },
    { title: 'Post-Launch Support<br/> and Iteration', details: 'We provide ongoing support and development post-launch, ensuring your product evolves with user needs and market trends for sustained success.' },
  ];
  const reasons = [
    "Proven Expertise in MVP Development: At Webkorps, we specialize in taking your Minimum Viable Product (MVP) and transforming it into a fully scalable, market-ready solution. Our experience in diverse industries allows us to understand your unique business needs and deliver solutions that drive growth.",
    "Agile Development Process: Our agile approach ensures that your product evolves smoothly from MVP to full-scale application. We work iteratively, allowing for continuous feedback and refinement, ensuring the final product meets both your expectations and market demands.",
    "Focus on Scalability and Performance: We design with the future in mind. Webkorps ensures your product is built on scalable architecture, capable of handling growth and increasing user demand without compromising performance or user experience.",
    "End-to-End Product Development: From user experience design to backend development, we provide comprehensive services that cover every aspect of product development. Our full-stack team ensures a seamless transition from MVP to a complete, polished product.",
    " Ongoing Support and Innovation: Webkorps is committed to your long-term success. After launching your product, we provide continuous support and incorporate new technologies and features, helping your product stay competitive and relevant in an ever-changing market."
  ];

  return (
    <div className=' overflow-hidden'>
      <Header />
      <div className="w-full text-center md:text-left overflow-hidden">
        <div className="flex flex-col md:flex-col ipad-pro:flex-row xl:flex-row items-center px-[5%] xl:px-[160px] ipad-pro:px-[100px] justify-center text-center lg:text-left pb-10 p-6 bg-[#FAFAFA]">
          <div className='content   w-full  flex flex-col '>
            <h1 className="text-[33px] leading-[38px] xl:leading-[46.2px] font-bold  w-[100%]">MVP to Product</h1>
            <h2 className="font-quicksand text-[28px] leading-[39.2px] font-bold text-center xl:text-left  ipad-pro:text-left text-[#072125] mt-3 md:mt-4 w-full">
            Transform Your MVP into a Scalable Product with Our Expertise.
            </h2>
            <p className="text-[#072125] mt-[20px] text-[18px] leading-[27.3px]  xl:w-[670px] ipad-pro:w-[350px]">
            Our services guide you through the journey from MVP to a full-fledged product, ensuring a seamless transition with optimal performance. We specialize in scaling your solution to meet market demands, enhancing functionality, and improving user experience. With tailored strategies and advanced tools, we help you unlock your product's potential, turning it into a robust, scalable solution that drives growth and achieves your business objectives.         </p>
          </div>
          <div className='content  mt-6 xl:mt-0 ipad-pro:mt-6 md:mt-6  w-full  flex flex-col  items-center  xl:items-end ipad-pro:items-end md:items-center '>
            <img width="800" height="600"  sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy" src="https://webkorps-app.s3.us-east-1.amazonaws.com/mvp-to-product-development.webp" alt="mvp-to-product-development" className="rounded-lg shadow-md w-full lg:w-[385px] lg:h-[415px] max-w-md" />
          </div>
        </div>

        {/* Offers Section */}
        <div className='flex lg:flex-row items-center  justify-center md:justify-start text-center  md:mx-[50px]  xl:ml-[203px] xl:mr-[213px] ipad-pro:mx-[100px] mt-[52px]   w-full mb-[28px]'>
          <h1 className="text-[40px] leading-[56px] font-bold text-center">What We Offer?</h1>
        </div>
        <div className="relative flex flex-col md:flex-row lg:flex-row flex-wrap justify-between w-full md:px-[30px] xl:pl-[203px] xl:pr-[213px] ipad-pro:px-[85px] px-[4%] mb-[89px]">
          {offers.map((offer, index) => (
            <div
              key={offer.id}
              className="relative z-10 w-full sm:w-full md:w-1/2 xl:w-[45%] ipad-pro:w-[50%] p-5"
            >
              <OfferCard key={index} title={offer.title} details={offer.details} />
            </div>
          ))}
          <img width="800" height="600"  sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"
            src="https://webkorps-app.s3.us-east-1.amazonaws.com/dot-elements.svg"
            alt="dot-elements"
            className="absolute bottom-[-20px] left-[86px] z-0 w-[27%]"
          />
        </div>




        {/* Why Choose Section */}
        <div className="px-[7%] xl:px-[304px]  ipad-pro:px-[100px] rounded-[10px] shadow-md relative">
          <div className="relative">
            <h3 className="text-2xl font-bold text-center mb-4 md:mb-[32px]">
            Why Choose Webkorps to Manage your IT Solution?
            </h3>

            <div className="relative w-full">
              <img width="800" height="600"  sizes="(max-width: 640px) 100vw, 
         (max-width: 1280px) 50vw, 
         33vw" loading="lazy"
                src={'https://webkorps-app.s3.us-east-1.amazonaws.com/circleleft.svg'}
                alt="circleleft"
                className="absolute top-0 left-[40%] transform -translate-x-[65%] translate-y-[70%] w-[90%] xl:translate-y-[70%] xl:-translate-x-[115%] max-w-[800px] h-[70%] xl:h-[60%] xl:w-[50%]  pointer-events-none z-0"
              />
              <ul className="relative grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-8 text-gray-700 z-10 pb-[85px]">
                {reasons.map((reason, index) => {
                  const [title, description] = reason.split(': ');

                  return (
                    <li key={index} className="flex mb-[30px]">
                      <b className="text-[33px] leading-[46px] font-bold font-quicksand text-[#20484F]">
                        {(index + 1).toString().padStart(2, '0')}
                      </b>
                      <p className="ml-[10px] mt-1 text-[13px] leading-[18px] font-normal font-quicksand text-[#072125]">
                        <strong className="mb-[10px] block text-[19px] leading-[26px] font-bold font-quicksand text-[#072125]">
                          {title}
                        </strong>
                        {description}
                      </p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Services;
